
.target-div .slide div {
  height: 250px;
  width: 100%;
}

.target-div .slide div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
