.organaogram .card {
  width: 70%;
  margin: 0 auto;
  border: none;
  height: 30rem;
}

.organaogram .card img {
  height: 100%;
}


