.nav-list {
  list-style: none;
  background-color: #055133;
  padding: 10px 20px;
  color: #fff;
  flex-wrap: wrap;
}
.nav-list li {
  margin: 0 15px;
}
.nav-div {
  width: 100%;
  height: 15vh;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid rgb(226, 223, 223);
  z-index: 999;
  background-color: #fff;
}

.logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container {
  display: flex;
  justify-content: space-between;
  position: sticky;
  align-items: center;
}

.logo {
  height: 50px;
  position: sticky;
  display: inline;
}

.logo-label {
  color: #055133;
  font-weight: 1000;
  font-size: larger;
}

.menu-list {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a {
  text-decoration: none;
}

.menu-list li a {
  color: #055133;
  font-weight: 400;
  padding: 5px 10px;
  margin: 0 10px;
}

.menu-list li a:hover,
.menu-list li a.active {
  border-bottom: 2px solid #055133;
  font-weight: 500;
  padding: 7px 12px;
  transition: all 0.4s ease-in-out;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 935px) {
  .nav-list {
    list-style: none;
    background-color: #055133 !important;
    padding: 10px 20px;
    color: #fff;
    flex-wrap: wrap;
  }
  .nav-list li {
    margin: 0 15px;
  }

  .nav-div {
    width: 100%;
    height: 15vh;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    border-bottom: 1px solid rgb(226, 223, 223);
    z-index: 999;
    background-color: #fff;
  }

  nav {
    flex-direction: column;
  }

  .menu-icon {
    display: block;
    color: #055133;
    font-size: 25px;
  }

  .fas {
    cursor: pointer;
  }

  .menu-icon .fa-times {
    transform: rotate(360deg);
    transition: all 0.3s ease-in-out;
  }

  .menu-icon .fa-bars {
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
  }

  .menu-list {
    position: absolute;
    flex-direction: column;
    top: -600%;
    right: 0;
    left: 0;
    transition: all 0.5s ease-in-out;
    z-index: 999;
    background-color: #000;
  }

  .menu-list-dark {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
  }
  .menu-list li a {
    color: #fff;
  }

  .menu-list.close {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #000;

    transition: all 0.5s ease-in-out;
  }

  .menu-list li {
    margin: 20px;
    font-size: 18px;

    transition: all 0.5s ease-in-out;
  }
}

@media screen and (max-width: 375px) {
  .menu-list {
    position: absolute;
    flex-direction: column;
    top: -630%;
    right: 0;
    left: 0;
    transition: all 0.5s ease-in-out;
    z-index: 999;
    background-color: #000;
  }
  .menu-list.close {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #000;
    transition: all 0.5s ease-in-out;
  }

  .menu-list li {
    margin: 16px;
    font-size: 16px;

    transition: all 0.5s ease-in-out;
  }
}
