.team h2 {
  color: #055133;
  font-weight: 400;
}

.team font {
  border-bottom: 1px solid #4b6e1f;
}

.top-padding {
  padding-top: 80px;
}

.carousel-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 450px;
  padding-top: 10px;
  margin-bottom: 50px;
}

.my-carousel {
  margin: 0 auto;
  width: 365px;
  height: 100%;
  perspective: 800px;
  padding-top: 30px;
}

.image-wrapper {
  height: 30vw;
}
.image-wrapper img {
  width: 100%;
  height: 100%;
}

.my-carousel-content {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateZ(-500px) rotateY(0);
  transform-style: preserve-3d;
  animation: my-carousel 25s infinite cubic-bezier(1, 0.015, 0.295, 1.225)
    forwards;
}

.my-carousel-item {
  width: 280px;
  height: 350px;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  /* overflow: hidden; */
}

.my-carousel-item img {
  width: 100% !important;
  height: 100%;
  border-radius: 5px;
  border: none;
}

.my-carousel-item:nth-child(1) {
  transform: rotateY(0) translateZ(600px);
}

.my-carousel-item:nth-child(2) {
  transform: rotateY(30deg) translateZ(600px);
}

.my-carousel-item:nth-child(3) {
  transform: rotateY(60deg) translateZ(600px);
}

.my-carousel-item:nth-child(4) {
  transform: rotateY(90deg) translateZ(600px);
}

.my-carousel-item:nth-child(5) {
  transform: rotateY(120deg) translateZ(600px);
}

.my-carousel-item:nth-child(6) {
  transform: rotateY(150deg) translateZ(600px);
}

.my-carousel-item:nth-child(7) {
  transform: rotateY(180deg) translateZ(600px);
}

.my-carousel-item:nth-child(8) {
  transform: rotateY(210deg) translateZ(600px);
}

.my-carousel-item:nth-child(9) {
  transform: rotateY(240deg) translateZ(600px);
}

.my-carousel-item:nth-child(10) {
  transform: rotateY(270deg) translateZ(600px);
}

.my-carousel-item:nth-child(11) {
  transform: rotateY(300deg) translateZ(600px);
}

.my-carousel-item:nth-child(12) {
  transform: rotateY(330deg) translateZ(600px);
}

.my-carousel-item:nth-child(13) {
  transform: rotateY(360deg) translateZ(600px);
}

/* Create a my-carousel animation  */
@keyframes my-carousel {
  0%,
  5.7% {
    transform: translateZ(-500px) rotateY(0);
  }
  8.5677%,
  14% {
    transform: translateZ(-500px) rotateY(-30deg);
  }
  16.8677%,
  22.3% {
    transform: translateZ(-500px) rotateY(-60deg);
  }
  25.1677%,
  30.6% {
    transform: translateZ(-500px) rotateY(-90deg);
  }
  33.4677%,
  38.9% {
    transform: translateZ(-500px) rotateY(-120deg);
  }
  41.7677%,
  47% {
    transform: translateZ(-500px) rotateY(-150deg);
  }

  50.0677%,
  55.3% {
    transform: translateZ(-500px) rotateY(-180deg);
  }

  58.4007%,
  63.6% {
    transform: translateZ(-500px) rotateY(-210deg);
  }

  66.7337%,
  71.9% {
    transform: translateZ(-500px) rotateY(-240deg);
  }

  75.0337%,
  80.2% {
    transform: translateZ(-500px) rotateY(-270deg);
  }

  83.3337%,
  88.5% {
    transform: translateZ(-500px) rotateY(-300deg);
  }
  91.667%,
  96.8% {
    transform: translateZ(-500px) rotateY(-330deg);
  }
  100% {
    transform: translateZ(-500px) rotateY(-360deg);
  }
}

/* TEAM MAIN */

/* Our Team
-------------------------------------------------------*/

.our-team .team-row {
  margin-left: -40px;
  margin-right: -40px;
}

.our-team {
  padding: 0 40px;
}

.our-team .container-fluid {
  padding: 0 50px;
}

.team-img {
  /* height: 25rem; */
  height: 26rem;
  width: 100%;
  border-radius: 10px;
}

.team-img img {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.team-member,
.team-img {
  position: relative;
  overflow: hidden;
}



.team-title {
  margin: 30px 0 7px;
}

.overlay {
  background-color: rgba(20, 20, 20, 0.7);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.team-details {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  padding: 5%;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.team-details p {
  color: #fff;
}

.team-img:hover .team-details {
  opacity: 1;
  margin-top: -80px;
}

.team-img:hover .overlay {
  opacity: 1;
}

.socials a {
  display: inline-block;
  width: 37px;
  height: 37px;
  background-color: transparent;
}

.socials i {
  line-height: 37px;
  color: #616161;
  font-size: 14px;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.team-details .socials i {
  color: #fff;
}

.socials a:hover i {
  color: #fff;
  background-color: #355c7d;
}

.card img {
  height: 19rem;
  width: 100%;
}

@media screen and (min-width: 1500px) {
  .card img {
    height: 13rem;
    width: 100%;
  }

  .team {
    margin-top: 30px;
    /* height: 45vh; */
  }
}

@media screen and (max-width: 990px) {
  .card img {
    height: 13rem;
    width: 100%;
  }

  .team {
    margin-top: 30px;
    /* height: 45vh; */
  }
}

@media screen and (max-width: 830px) {
  .card img {
    height: 12rem;
    width: 100%;
  }

  .team {
    margin-top: 30px;
    /* height: 52vh; */
  }
}

@media screen and (max-width: 675px) {
  .card img {
    height: 11.5rem;
    width: 100%;
  }
  .team {
    margin-top: 30px;
    /* height: 52vh; */
  }
}

@media screen and (max-width: 420px) {
  .card img {
    height: 16rem;
    width: 100%;
  }

  .carousel-container {
    margin-bottom: 0;
  }
  .team {
    margin-top: 30px;
    /* height: 65vh; */
  }

  .my-carousel {
    margin: 0 auto;
    width: 365px;
    height: 100%;
    perspective: 100px;
    padding-top: 30px;
  }

  .my-carousel-item {
    width: 100%;
    height: 400px;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    /* overflow: hidden; */
  }
}

@media screen and (max-width: 380px) {
  .card img {
    height: 16rem;
    width: 100%;
  }

  .team {
    margin-top: 30px;
    /* height: 82vh; */
  }

  .carousel-container {
    margin-bottom: 0;
  }

  .my-carousel {
    margin: 0 auto;
    width: 365px;
    height: 100%;
    perspective: 100px;
    padding-top: 30px;
  }

  .my-carousel-item {
    width: 100%;
    height: 400px;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.ant-card-cover {
  height: 400px;
  width: 300px;
}

.ant-card-cover img {
  height: 100%;
  width: 100%;
}

.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.76);
}
