.row {
  padding: 0;
}

.programs h3 a,
.programs h3 a:hover,
.programs h3 a:visited {
  color: #77b32e;
  font-weight: 400;
}

.programs {
  width: 80%;
  margin: 0 auto;
  /* margin: 0 ; */
}

.video-player {
  width: 70%;
  height: auto;
  margin: 0 auto;
}

.video-player video {
  width: 100%;
  height: auto;
}

.gallery {
  padding-bottom: 20px;
}

.program-title {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #77b32e;
}

.view-more {
  padding-top: 50px;
}

.view-more a {
  color: #fff;
  background-color: #55da20;
  border-radius: 5px;
  padding: 3px 10px;
}

.view-more a:hover {
  color: #fff;
  background-color: #3a9217;
  padding: 3px 10px;
}

.row {
  /* padding: 0 50px; */
  margin: 0;
}

.image-div {
  /* padding: 2px; */
  height: 100%;
}

.image-div:hover {
  cursor: zoom-in;
}

.img-wrapper {
  width: 100%;
  height: 200px;
}

.image {
  border-radius: 15px;
  width: 100%;
  height: 100%;
}

.modal-header {
  border: none;
  /* padding: 0; */
}

@media screen and (max-width: 574px) {
  /*  */
}
