.news li {
}

.news li a {
  color: #055133;
}

.news li a:hover {
  color: #6c920b;
  text-decoration: underline;
}
