.video-player {
  width: 70%;
  height: auto;
  margin: 0 auto;
}

.video-player video {
  width: 100%;
  height: auto;
}

.gallery {
  padding-bottom: 20px;
}

.gallery-title {
  color: #77b32e;
}

.modal-img {
  height: 100%;
}

.view-more a {
  color: #fff;
  background-color: #55da20;
  border-radius: 5px;
  padding: 3px 10px;
}

.view-more a:hover {
  color: #fff;
  background-color: #3a9217;
  padding: 3px 10px;
}
bootstrap
  justify-selfbootstrap
  justify-sebootstrap
  justify-selflfbootstrap
  justify-self
  .row {
  padding: 0 50px;
  margin: 0;
}
.image-div {
  padding: 5px;
}

.image-div:hover {
  cursor: zoom-in;
}

.img-wrapper {
  width: 100%;
  height: 100%;
}

.image {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal-header {
  border: none;
  /* padding: 0; */
}

@media screen and (max-width: 780px) {
  .video-player {
    width: 100%;
    height: auto;
    margin: 0 auto;
    background-color: #3a9217;
    padding: 0;
  }

  .video-player video {
    width: 100%;
    height: auto;
  }
}
