@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 4));
  }
}
.partners {
  background: white;
  /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125); */
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 30px;
}

.partners .slide img {
  object-fit: contain;
}
.partners::before,
.partners::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.partners::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.partners::before {
  left: 0;
  top: 0;
}
.partners .slide-track {
  animation: scroll 30s linear infinite;
  display: flex;
  width: 100%;
}
.partners .slide {
  height: 100px;
  width: 250px;
}
