.img-top {
  height: 100%;
}

.img-top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img-full-width {
  width: 100%;
  height: 100%;
}
