.little-padding {
  margin-top: 30px;
}

.center-writeup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome-intro {
  background-color: aqua;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about-container {
  height: 100vh;
}
.about-row {
  height: 100vh;
}

.values-row {
  height: 30vh;
}

.title {
  color: #055133;
}

.welcome p {
  padding-right: 30px;
}

.dg-photo {
  width: 100%;
}

.form-check-input:checked {
  background-color: #055133;
  border-color: #055133;
}

.mandate {
  text-align: left;
  width: 100%;
}

.mandate li {
  list-style-type: square;
  margin: 0 15px;
  padding: 10px;
}

.photo-divs {
  border-radius: 10px;
  padding: 20px;
}

.photo-divs img {
  border-radius: 10px;
}

.col-height {
  height: 70%;
}

.text-div {
  width: 100%;
  height: 100%;
}

.value-wrapper {
  border: 1px solid #6c920b;
  height: 100%;
}

.about-shadow {
  box-shadow: 0px 2px 8px 0px rgba(71, 66, 66, 0.65);
  -webkit-box-shadow: 0px 2px 8px 0px rgba(71, 66, 66, 0.65);
  -moz-box-shadow: 0px 2px 8px 0px rgba(71, 66, 66, 0.65);
  border-top: 10px #6c920b solid;
}

.photo-div {
  height: 450px;
  width: 100%;
}

.photo-div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.welcome {
  padding-top: 0px;
}

@media screen and (max-width: 912px) {
  .photo-div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .welcome {
    padding-top: 0;
  }
}

@media screen and (max-width: 820px) {
  .photo-div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .welcome {
    padding-top: 0;
  }
  .welcome p {
    text-align: center !important;
  }
}

@media screen and (max-width: 768px) {
  .center-writeup {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .welcome {
    padding-top: 0;
  }
  .welcome p {
    text-align: center !important;
  }
}

@media screen and (max-width: 720px) {
  .center-writeup {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (min-width: 600px) {
  .center-writeup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .row {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mandate li {
    list-style-type: square;
  }
  .welcome {
    padding-top: 0;
  }

  .about-row {
    margin: 0;
  }
}

@media screen and (max-width: 414px) {
  .center-writeup {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  mandate {
    padding: 0;
  }
  .welcome {
    padding-top: 0;
  }
  .welcome p {
    text-align: center !important;
  }
}
