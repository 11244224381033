.footer {
  color: #fff;
  height: 100%;
  /* padding: 30px; */
  background-color: #055133;
}

a {
  color: #fff;
}

.list-group-item {
  background-color: #055133;
  border: none;
}

.footer-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
ul {
  margin: 0;
  padding: 0;
}

.footer a {
  text-decoration: none;
  color: #fff;
}

.footer a:hover {
  background-color: #060a01;
  padding: 10px 20px;
  color: #fff;
  transition: all 0.2s ease-in-out;
}

.ul-list {
  list-style-type: none;
  margin: 10px;
}

.ul-list li {
  font-size: 17px;
  margin: 0 15px;
}
.copyright {
  list-style-type: none;
  font-size: 15px !important;
}
