.offer-div {
  /* height: 180px; */
  /* width: 250px; */
}

/* .offer-div .slide, */
.offer-div .slide div {
  height: 170px;
  width: 100%;
  /* background-color: red; */
}

.offer-div .slide div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
