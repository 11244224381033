@import "~antd/dist/antd.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

h1,
h2,
h3 {
  color: #055133;
}

.box-shadow {
  box-shadow: 0px 2px 8px 0px rgba(71, 66, 66, 0.65);
  -webkit-box-shadow: 0px 2px 8px 0px rgba(71, 66, 66, 0.65);
  -moz-box-shadow: 0px 2px 8px 0px rgba(71, 66, 66, 0.65);
  border-top: 10px #6c920b solid;
  height: 100%;
  padding: 10px 20px !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  transition: all 0.25s ease-in;
  opacity: 1.4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: rgba(10, 9, 9, 0.295);
  border: 0;
  font-size: 32px;
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

body {
  font-size: 18px;
}

.readmore-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content img {
  width: 100%;
}

.readmore-btn button {
  border: none;
  outline: none;
  padding: 2px 10px;
  color: #fff;
  font-weight: 400;
  background-color: #6c920b;
  border-radius: 2px;
}

ul {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

.more a {
  color: #fff;
  background-color: #6c920b;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 15px;
}

.more a:hover {
  color: #fff;
  background-color: #4b6e1f;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 15px;
}

.img-wrapper {
  height: 100%;
}

.column-height {
  height: 120px;
}

.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  background-color: black !important;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
}
