/* ken burn */
.intro {
  position: relative;
  margin: 2em;
  padding: 0.2em 0.6em;
  cursor: pointer;
  color: #fff;
  font-size: 2em;
  max-width: 26em;
}
.intro h1 {
  margin-bottom: 0.5em;
  line-height: 1;
  font-weight: 100;
}
#CrossFade {
  background: #fff;
  display: flex;
  align-items: flex-end;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
#CrossFade img {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  height: auto;
  background: #000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  transform: scale(1.4) rotate(12deg);
  -webkit-animation: CrossFade 24s infinite;
  animation: CrossFade 24s infinite;
}
#CrossFade img:nth-child(3) {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}
#CrossFade img:nth-child(2) {
  -webkit-animation-delay: 12s;
  animation-delay: 12s;
}
#CrossFade img:nth-child(1) {
  -webkit-animation-delay: 18s;
  animation-delay: 18s;
}

.home {
  /* padding:48px */
}

.gallery-title {
  padding-top: 50px;
}

.gallery-title a {
  color: #77b32e;
}

.gallery-title a:hover {
  border-bottom: 1px solid #528612;
}

.gallery-title:hover {
  cursor: pointer;
  /* font-size: 50px; */
}
